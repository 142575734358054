/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import { ContentWrapperComponentProps } from 'Component/ContentWrapper/ContentWrapper.type';

import {
    ContentWrapperComponent as SourceContentWrapper
} from 'SourceComponent/ContentWrapper/ContentWrapper.component';

import './ContentWrapper.override.style';

/**
 * Content Wrapper
 * @class ContentWrapper
 * @namespace Component/ContentWrapper/Component
 */
export class ContentWrapperComponent extends SourceContentWrapper {
   
}

export default ContentWrapperComponent;
