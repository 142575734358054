
import PropTypes from 'prop-types';


export const OverlayActionType = {
    TOGGLE_OVERLAY: 'TOGGLE_OVERLAY',
    HIDE_ACTIVE_OVERLAY: 'HIDE_ACTIVE_OVERLAY',
    HIDE_ACTIVE_POPUP: 'HIDE_ACTIVE_POPUP',
};

export const ToggleOverlayByKeyAction = PropTypes.shape({
    type: PropTypes.oneOf([OverlayActionType.TOGGLE_OVERLAY]).isRequired,
    overlayKey: PropTypes.string.isRequired,
});

export const HideActiveOverlayAction = PropTypes.shape({
    type: PropTypes.oneOf([OverlayActionType.HIDE_ACTIVE_OVERLAY]).isRequired,
});

export const HideActivePopupAction = PropTypes.shape({
    type: PropTypes.oneOf([OverlayActionType.HIDE_ACTIVE_POPUP]).isRequired,
    payload: PropTypes.bool.isRequired,
});

export const OverlayAction = PropTypes.oneOfType([
    ToggleOverlayByKeyAction,
    HideActiveOverlayAction,
    HideActivePopupAction,
    // Assuming ShowPopupAction is defined using PropTypes.shape elsewhere
    // ShowPopupAction,
]);

export const OverlayStore = PropTypes.shape({
    activeOverlay: PropTypes.oneOfType([
        // Assuming PopupPayload keys are strings
        PropTypes.string,
        PropTypes.oneOf(['']),
    ]).isRequired,
    areOtherOverlaysOpen: PropTypes.bool.isRequired,
});

export const RootState = PropTypes.shape({
    OverlayReducer: OverlayStore.isRequired,
});


// import {
//     OverlayActionType as sourceOverlayActionType,
//    // ToggleOverlayByKeyAction as sourceToggleOverlayByKeyAction,
//     //HideActiveOverlayAction as sourceHideActiveOverlayAction,
//    //HideActivePopupAction as sourceHideActivePopupAction,
//     //OverlayAction as sourceOverlayAction,
//    // OverlayStore as sourceOverlayStore,
//    // RootState as sourceRootState,
// } from 'SourceStore/Overlay/Overlay.type';

// export const OverlayActionType = sourceOverlayActionType;
// //export const ToggleOverlayByKeyAction = sourceToggleOverlayByKeyAction;
// //export const HideActiveOverlayAction = sourceHideActiveOverlayAction;
// //export const HideActivePopupAction = sourceHideActivePopupAction;
// //export const OverlayAction = sourceOverlayAction;
// //export const OverlayStore = sourceOverlayStore;
// //export const RootState = sourceRootState;
