/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { lazy } from 'react';
import Gtm from 'Component/Gtm';
// import NoMatch from 'Route/NoMatch';
import {
    // CategoryPage,
    // CmsPage,
    // ProductPage,
    UrlRewritesComponent as SourceUrlRewrites
} from 'SourceRoute/UrlRewrites/UrlRewrites.component';

// export {
//     ProductPage,
//     CategoryPage,
//     CmsPage
// };

import {
    // TYPE_CATEGORY,
    // TYPE_CMS_PAGE,
    // TYPE_NOTFOUND,
    // TYPE_PRODUCT,
    UrlRewritePageType
} from './UrlRewrites.config';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

export const ProductPage = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product" */ 'Route/ProductPage'),
);

export const CategoryPage = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/CategoryPage'),
);
export const CmsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/CmsPage'));
export const NoMatch = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/NoMatch'));




/** @namespace Pwa/Route/UrlRewrites/Component/UrlRewritesComponent */
export class UrlRewritesComponent extends SourceUrlRewrites {
    renderContent() {
        const { props, type } = this.props;
        let {
            match,
            pageIds,
            productSKU,
            id,
            categoryIds,
            displayMode,
            location,
            category_sort
        } = props;

        switch (type) {
            case UrlRewritePageType.PRODUCT:

                if(!productSKU && window.actionName?.sku){
                    productSKU = window.actionName.sku;
                }

                if(!id && window.actionName?.id){
                    id = window.actionName.id;
                }

                if(!id && !productSKU){
                    setLoadedFlag();
                }

                if(!productSKU){
                    return this.renderDefaultPage();
                }

                return (
                    <Gtm pageType="product">
                        {/* {this.renderProductPage()} */}
                        <ProductPage 
                            match={ match }
                            productSKU={ productSKU }
                            productID={ id }
                            key={ id }
                         />
                    </Gtm>
                );
            case UrlRewritePageType.CMS_PAGE:
                return (
                    <Gtm pageType="other">
                        <CmsPage
                            match={match}
                            pageIds={pageIds}
                        />
                    </Gtm>
                );

            case UrlRewritePageType.CATEGORY:
                return (
                    <Gtm pageType="listing">
                        <CategoryPage 
                            match={ match }
                            categoryIds={ categoryIds }
                            displayMode={ displayMode }
                            location={ location }
                            categoryDefaultSortBy={ category_sort }
                        />
                    </Gtm>
                );
            case UrlRewritePageType.NOTFOUND:
                // EVENTO GA4
                window?.dataLayer?.push({
                    event: 'error',
                    error_type: '404'
                });

                return <NoMatch {...props} />;
            default:
                return this.renderDefaultPage();
        }
    }

    render() {
        return this.renderContent();
    }
}

export default UrlRewritesComponent;
