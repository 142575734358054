// TODO update this import when renamed
import { GTM_APPEND_DATA } from './GTM.action';

/** @namespace Pwa/Store/GTM/Reducer/getInitialState */
export const getInitialState = () => ({
    data: []
});

/** @namespace Pwa/Store/GTM/Reducer/GTMReducer */
export const GTMReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case GTM_APPEND_DATA:
        const { data } = action;

        window?.dataLayer?.push(data);

        return {
            ...state,
            data: window?.dataLayer
        };

    default:
        return state;
    }
};

export default GTMReducer;
