/* eslint-disable max-len */
import axios from 'axios';

import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from '@scandipwa/scandipwa/src/util/Request/Config';
import getStore from '@scandipwa/scandipwa/src/util/Store';

export const MC_WAREHOUSE = 'mc_warehouse';

/** @namespace Pwa/Util/Availability/setWarehouse */
export const setWarehouse = async (warehouse) => {
    const state = getStore().getState();
    const storeCode = state.ConfigReducer.code;
    if (storeCode === 'default' && warehouse) {
        const isCartografieActive = await axios.get('/rest/V1/use_custom_maps/get/1');
        if (isCartografieActive.data === '1') {
            const codcomune = warehouse?.value?.code;
            const zipcode = warehouse?.value?.zipcode;
            const depComp = await axios.get(`/rest/V1/mc_livemaps/getCodeFromService/${ codcomune }/${ zipcode}`);
            const newWarehouse = warehouse;

            if (depComp !== undefined && depComp?.data[0]?.depComp && depComp?.data[0]?.depComp !== '0') {
                newWarehouse.value.code = depComp?.data[0]?.depComp;
            }

            BrowserDatabase.setItem(newWarehouse, MC_WAREHOUSE, ONE_MONTH_IN_SECONDS);
        } else {
            BrowserDatabase.setItem(warehouse, MC_WAREHOUSE, ONE_MONTH_IN_SECONDS);
        }
    } else {
        BrowserDatabase.setItem(warehouse, MC_WAREHOUSE, ONE_MONTH_IN_SECONDS);
    }
};

/** @namespace Pwa/Util/Availability/getWarehouse */
export const getWarehouse = () => {
    const res = BrowserDatabase.getItem(MC_WAREHOUSE);
    if (!res) {
        return undefined;
    }

    return res;
};
