import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    DEFAULT_STATE,
    mapDispatchToProps,
    mapStateToProps,
    NavigationAbstractContainer as SourceNavigationAbstractContainer
} from 'SourceComponent/NavigationAbstract/NavigationAbstract.container';

export const DISABLE_OVERLAY_HIDE_ON_DESKTOP = [
    'ORDER_BY_OVERLAY'
];

export {
    DEFAULT_STATE,
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Pwa/Component/NavigationAbstract/Container/NavigationAbstractContainer */
export class NavigationAbstractContainer extends SourceNavigationAbstractContainer {
    static propTypes ={
        activeOverlay: PropTypes.string.isRequired
    };

    handleDesktopRouteChange() {
        const {
            hideActiveOverlay,
            setNavigationState,
            activeOverlay
        } = this.props;

        setNavigationState(this.routeMap['/']);
        if (!DISABLE_OVERLAY_HIDE_ON_DESKTOP.includes(activeOverlay)) {
            hideActiveOverlay();
        }

        return {};
    }

    handleMobileUrlChange(history) {

        const { prevPathname } = this.state;
        const { pathname } = history;

        if (prevPathname === pathname) {
            return {};
        }

        return this.handleMobileRouteChange(history);

    }

    async handleMobileRouteChange(history) {

        const {
            // hideActiveOverlay,
            setNavigationState,
            navigationState: { name },
        } = this.props;

        const { pathname } = history;

        // Find the new state name
        const newNavigationState = this.getNavigationState();

        // Update the state if new name is set
        if (name !== newNavigationState.name) {
            await setNavigationState(newNavigationState);
        }

        // CUSTOM EVENT
        const event = await new CustomEvent("UPDATE_NAVIGATION_AFTER");
        await document.dispatchEvent(event);

        return { prevPathname: pathname };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationAbstractContainer);
