

import PropTypes from 'prop-types';

export const NavigationActionType = {
    CHANGE_NAVIGATION_STATE: 'CHANGE_NAVIGATION_STATE',
    GOTO_PREVIOUS_NAVIGATION_STATE: 'GOTO_PREVIOUS_NAVIGATION_STATE',
};

export const NavigationType = {
    TOP_NAVIGATION_TYPE: 'TOP_NAVIGATION_TYPE',
    BOTTOM_NAVIGATION_TYPE: 'BOTTOM_NAVIGATION_TYPE',
};

export const NavigationState = PropTypes.shape({
    force: PropTypes.bool,
    hiddenElements: PropTypes.arrayOf(PropTypes.string),
    isHidden: PropTypes.bool,
    isHiddenOnMobile: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBackClick: PropTypes.func,
    onCancelClick: PropTypes.func,
    onCloseClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onOkClick: PropTypes.func,
    shouldNotGoToPrevState: PropTypes.bool,
    title: PropTypes.string,
});

export const ChangeNavigationStateAction = PropTypes.shape({
    type: PropTypes.oneOf([NavigationActionType.CHANGE_NAVIGATION_STATE]).isRequired,
    navigationType: PropTypes.oneOf(Object.values(NavigationType)).isRequired,
    navigationState: NavigationState.isRequired,
});

export const GoToPreviousNavigationStateAction = PropTypes.shape({
    type: PropTypes.oneOf([NavigationActionType.GOTO_PREVIOUS_NAVIGATION_STATE]).isRequired,
    navigationType: PropTypes.oneOf(Object.values(NavigationType)).isRequired,
});

export const NavigationAction = PropTypes.oneOfType([
    ChangeNavigationStateAction,
    GoToPreviousNavigationStateAction,
]);

export const NavigationStore = PropTypes.shape({
    [NavigationType.TOP_NAVIGATION_TYPE]: PropTypes.shape({
        navigationState: NavigationState.isRequired,
        navigationStateHistory: PropTypes.arrayOf(NavigationState).isRequired,
    }),
    [NavigationType.BOTTOM_NAVIGATION_TYPE]: PropTypes.shape({
        navigationState: NavigationState.isRequired,
        navigationStateHistory: PropTypes.arrayOf(NavigationState).isRequired,
    }),
});

export const RootState = PropTypes.shape({
    NavigationReducer: NavigationStore.isRequired,
});


// import {
//     NavigationActionType as sourceNavigationActionType,
//     NavigationType as sourceNavigationType,
//    // NavigationState as sourceNavigationState,
//   //  ChangeNavigationStateAction as sourceChangeNavigationStateAction,
//    // GoToPreviousNavigationStateAction as sourceGoToPreviousNavigationStateAction,
//  //   NavigationAction as sourceNavigationAction,
//    // NavigationStore as sourceNavigationStore,
//   //  RootState as sourceRootState,
// } from 'SourceStore/Navigation/Navigation.type';

// export const NavigationActionType = sourceNavigationActionType;
// export const NavigationType = sourceNavigationType;
// //export const NavigationState = sourceNavigationState;
// //export const ChangeNavigationStateAction = sourceChangeNavigationStateAction;
// //ort const GoToPreviousNavigationStateAction = sourceGoToPreviousNavigationStateAction;
// //export const NavigationAction = sourceNavigationAction;
// //export const NavigationStore = sourceNavigationStore;
// //export const RootState = sourceRootState;
