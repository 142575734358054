import PropTypes from 'prop-types';

export const ModsType = PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
]));

export const MixType = PropTypes.shape({
    block: PropTypes.string,
    elem: PropTypes.string,
    mods: ModsType,
    // Recursive type definition
    
});

export const mix=MixType

export const ChildrenType = PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
]);

export const RefType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
]);

export const MetaTitleType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // Using object to handle unknown type
]);

// TypeScript types and utility types cannot be directly converted to PropTypes
// so they are not included in the JavaScript conversion.

// For example, Merge, Url, NetworkError, ObjectEntries are utility types or type aliases
// and they don't have a direct equivalent in PropTypes.



// import {
//     ModsType as sourceModsType,
//     MixType as sourceMixType,
//     ChildrenType as sourceChildrenType,
//     RefType as sourceRefType,
//     MetaTitleType as sourceMetaTitleType,
// } from '@scandipwa/scandipwa/src/type/Common.type';

// export const ModsType = sourceModsType;
// export const MixType = sourceMixType;
// export const ChildrenType = sourceChildrenType;
// export const RefType = sourceRefType;
// export const MetaTitleType = sourceMetaTitleType;